/** @module NotFoundPage
 *  @since 2023.02.20, 23:17
 *  @changed 2023.02.20, 23:17
 */

// TODO To use `_error` page? See `https://nextjs.org/docs/advanced-features/custom-error-page`.

import * as siteConfig from '@/config/site';
import { GenericPageLayout } from '@/layout';
import { subPageTitle } from '@/helpers';
import { NotFoundSection } from '@/components';

export default function NotFoundPage(): JSX.Element {
  const pageTitle = siteConfig.notFoundTitle;
  const title = subPageTitle(pageTitle);
  return (
    <GenericPageLayout title={title}>
      <NotFoundSection />
    </GenericPageLayout>
  );
}
